import { Outlet, createRootRoute } from '@tanstack/react-router';
import useKeycloak from '../hooks/useKeycloak';
import { Spinner } from '@lbc-toolkit/spinner';

export const Route = createRootRoute({
	component: RootComponent,
});

function RootComponent() {
	const { isInitialized, tokenInitialized } = useKeycloak();

	if (!isInitialized || !tokenInitialized) {
		return <Spinner spin={true} />;
	}

	return <Outlet />;
}
