/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConnectorInstanceDetailsDto } from '../models/ConnectorInstanceDetailsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConnectorInstancesService {

    /**
     * Creates new connector instance
     * @param componentId Identifier of the component that connector instance belongs
     * @param connectorId Identifier of the connector type that connector instances created from
     * @returns ConnectorInstanceDetailsDto Success
     * @throws ApiError
     */
    public static createConnectorInstance(
componentId: string,
connectorId: string,
): CancelablePromise<Array<ConnectorInstanceDetailsDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/components/{componentId}/connectors/{connectorId}',
            path: {
                'componentId': componentId,
                'connectorId': connectorId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get all connector instances by given component.
     * @param componentId Identifier of the component
     * @returns ConnectorInstanceDetailsDto Success
     * @throws ApiError
     */
    public static getConnectorInstancesByComponent(
componentId: string,
): CancelablePromise<Array<ConnectorInstanceDetailsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/components/{componentId}/connectors',
            path: {
                'componentId': componentId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Deletes an existing connector instance
     * @param connectorInstanceId Identifier of the connector instance
     * @param componentId 
     * @returns void 
     * @throws ApiError
     */
    public static deleteConnectorInstance(
connectorInstanceId: string,
componentId: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/components/{componentId}/connectors/{connectorInstanceId}',
            path: {
                'connectorInstanceId': connectorInstanceId,
                'componentId': componentId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

}
