const Config = {
	WarehouseServerUrl: process.env.REACT_APP_WAREHOUSE_SERVER_URL,
	CatalogServerUrl: process.env.REACT_APP_CATALOG_SERVER_URL,
	CatalogClientUrl: process.env.REACT_APP_CATALOG_CLIENT_URL,
	ComposerClientUrl: process.env.REACT_APP_COMPOSER_CLIENT_URL,
	KeycloakUrl: process.env.REACT_APP_KEYCLOAK_URL,
	KeycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM,
	KeycloakClientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};

export default Config;
