import { ConnectorDirection } from '../services/warehouse';

export interface ToastBar {
	show: boolean;
	message: string;
	severity: Severity;
}

export interface ArrayPosition {
	position: number;
	value: number;
}

export interface TargetMode {
	active: boolean;
	connectorId: string;
}

export interface Connector {
	id: string;
	name: string;
	uuidKF: string;
}

export interface SortingOption {
	property: string;
	ascending: boolean;
}

export interface ConnectorChangeState {
	sameConnector: boolean;
	newMeshUuid: string;
	oldMeshUuId: string;
	connectorId: string;
	direction: ConnectorDirection;
}

export enum Severity {
	default = 'default',
	warning = 'warning',
	alert = 'alert',
	success = 'success',
}

export enum DataType {
	string = 0,
	double = 1,
	integer = 2,
	boolean = 3,
}
