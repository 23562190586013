// STYLING
import './styling/app.css';
// COMPONENTS
import { ToolkitProvider } from '@lbc-toolkit/toolkit-provider';
// REDUX
import { Provider } from 'react-redux';
import { store } from './context/store';
// COMFIGURATION
import Config from './configuration/config';
// API
import { OpenAPI as CatalogAPI } from './services/catalog';
import { OpenAPI as WarehouseAPI } from './services/warehouse';
// Trajnslations
import { ToastContainer } from '@lbc-toolkit/toast';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { routeTree } from './routeTree.gen';
import { TolgeeProvider, Tolgee, FormatSimple, DevTools } from '@tolgee/react';
import { useEffect } from 'react';
import { FormatIcu } from '@tolgee/format-icu';

function App() {
	CatalogAPI.BASE = Config.CatalogServerUrl ?? '';
	WarehouseAPI.BASE = Config.WarehouseServerUrl ?? '';

	const tolgee = Tolgee().use(DevTools()).use(FormatIcu()).init({
		apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
		apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
		defaultLanguage: 'de',
	});

	useEffect(() => {
		console.log(process.env.REACT_APP_TOLGEE_API_URL);
		console.log(process.env.REACT_APP_TOLGEE_API_KEY);
	}, []);

	const router = createRouter({ routeTree });

	return (
		<Provider store={store}>
			<TolgeeProvider tolgee={tolgee} fallback='Loading...'>
				<div className='App'>
					<ToastContainer position='top-right' width='408px' />
					<ToolkitProvider>
						<RouterProvider router={router} />
					</ToolkitProvider>
				</div>
			</TolgeeProvider>
		</Provider>
	);
}

export default App;
