// External Libraries and Frameworks
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Spinner } from '@lbc-toolkit/spinner';
// React
import { useEffect } from 'react';

export const Route = createFileRoute('/')({
	component: Index,
});

function Index() {
	const navigate = useNavigate();

	useEffect(() => {
		navigate({ to: '/components/' });
	}, [navigate]);

	return <Spinner />;
}
