/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StorageService {

    /**
     * Downloads a model
     * @param modelName name of the file
     * @returns string Success
     * @throws ApiError
     */
    public static downloadModel(
modelName: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/storage/{modelName}/model',
            path: {
                'modelName': modelName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Uploads a model to storage
     * @param formData 
     * @returns string Success
     * @throws ApiError
     */
    public static uploadModel(
formData?: {
file?: Blob;
},
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/storage/model',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

}
