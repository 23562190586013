import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Severity, ToastBar } from '../models/models';
import { ClassificationDto } from '../services/catalog';

export interface AppReducerState {
	user: null;
	token: string;
	language: string;
	toast: ToastBar;
	createMode: boolean;
	detailsMode: boolean;
	deleteMode: boolean;
	activeDetailsTab: string;
	selectedClassification: ClassificationDto | null;
}

export const initialState: AppReducerState = {
	user: null,
	token: '',
	language: 'EN',
	toast: {
		show: false,
		message: '',
		severity: Severity.default,
	},
	createMode: false,
	detailsMode: false,
	deleteMode: false,
	activeDetailsTab: 'general',
	selectedClassification: null,
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<null>) => {
			state.user = action.payload;
		},
		setToast: (state, action: PayloadAction<ToastBar>) => {
			state.toast = action.payload;
		},
		setLanguage: (state, action: PayloadAction<string>) => {
			state.language = action.payload;
		},
		setCreateMode: (state, action: PayloadAction<boolean>) => {
			state.createMode = action.payload;
		},
		setDetailsMode: (state, action: PayloadAction<boolean>) => {
			state.detailsMode = action.payload;
		},
		setDeleteMode: (state, action: PayloadAction<boolean>) => {
			state.deleteMode = action.payload;
		},
		setActiveDetailsTab: (state, action: PayloadAction<string>) => {
			state.activeDetailsTab = action.payload;
		},
		setSelectedClassification: (state, action: PayloadAction<any>) => {
			state.selectedClassification = action.payload;
		},
	},
});

export const {
	setUser,
	setToast,
	setLanguage,
	setCreateMode,
	setDetailsMode,
	setDeleteMode,
	setActiveDetailsTab,
	setSelectedClassification,
} = appSlice.actions;

export default appSlice.reducer;
