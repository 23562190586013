/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConnectorDetailsDto } from '../models/ConnectorDetailsDto';
import type { ConnectorSimpleDto } from '../models/ConnectorSimpleDto';
import type { CreateConnectorDto } from '../models/CreateConnectorDto';
import type { UpdateConnectorDto } from '../models/UpdateConnectorDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConnectorsService {

    /**
     * Gets connector by given id.
     * @param connectorId Identifier of the connector
     * @returns ConnectorDetailsDto Success
     * @throws ApiError
     */
    public static getConnector(
connectorId: string,
): CancelablePromise<ConnectorDetailsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/connectors/{connectorId}',
            path: {
                'connectorId': connectorId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Deletes an existing connector
     * @param connectorId Delete connector id
     * @returns void 
     * @throws ApiError
     */
    public static deleteConnector(
connectorId: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/connectors/{connectorId}',
            path: {
                'connectorId': connectorId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Gets all Connectors without filter
     * @returns ConnectorSimpleDto Success
     * @throws ApiError
     */
    public static getConnectors(): CancelablePromise<Array<ConnectorSimpleDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/connectors',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Creates new connector
     * @param requestBody Create connector body
     * @returns ConnectorSimpleDto Created
     * @throws ApiError
     */
    public static createConnector(
requestBody?: CreateConnectorDto,
): CancelablePromise<ConnectorSimpleDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/connectors',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Updates an existing connector
     * @param requestBody Update connector input
     * @returns ConnectorSimpleDto Created
     * @throws ApiError
     */
    public static updateConnector(
requestBody?: UpdateConnectorDto,
): CancelablePromise<ConnectorSimpleDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/connectors',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param connectorId 
     * @returns string Success
     * @throws ApiError
     */
    public static getDependencies(
connectorId: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/connectors/{connectorId}/dependencies',
            path: {
                'connectorId': connectorId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

}
